import { coApplicantHttpClient, httpClient } from '@/utils/http-client'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { logRocketIdentify } from '@/services/marketing'

const getApplicantReturning = async (returnToken: string) => {
    const response = await httpClient.get('/applicantReturning', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    logRocketIdentify()
    return response
}

const saveApplicantSessionResponse = (response: { data: any }) => {
    const data = response.data
    const payload = data?.payload
    appSessionStorage.setItemIfPresent(localStorageKey.loanApplicationId, payload?.loanApplicationId, (id) => id.toString())
    appSessionStorage.setItemIfPresent(localStorageKey.phoneNumber, payload?.phoneNumber)

    if (payload && payload.jwt) {
        logger.info(`Saving applicant session response - applicant id: ${payload.applicantId} / loan application id: ${payload.loanApplicationId}`)

        appSessionStorage.setItemIfPresent(localStorageKey.jwtTokens, payload.jwt, (jwt) => JSON.stringify(jwt))
        appSessionStorage.setItemIfPresent(localStorageKey.coApplicantJwtTokens, payload.coApplicantJwtTokens, (jwt) => JSON.stringify(jwt))

        appSessionStorage.setItemIfPresent(localStorageKey.applicantId, payload.applicantId, (id) => id.toString())

        appSessionStorage.setItemIfPresent(localStorageKey.experimentName, payload.experimentName)

        appSessionStorage.setItemIfPresent(localStorageKey.inviteCode, payload.inviteCode)

        appSessionStorage.setItemIfPresent(localStorageKey.phoneNumber, payload.phoneNumber)
        appSessionStorage.setItemIfPresent(localStorageKey.coApplicantPhoneNumber, payload.coApplicantPhoneNumber)

        appSessionStorage.setItemIfPresent(localStorageKey.firstName, payload.firstName)
        appSessionStorage.setItemIfPresent(localStorageKey.lastName, payload.lastName)

        appSessionStorage.setItemIfPresent(localStorageKey.coApplicantFirstName, payload.coApplicantFirstName)
        appSessionStorage.setItemIfPresent(localStorageKey.coApplicantLastName, payload.coApplicantLastName)

        appSessionStorage.setItemIfPresent(localStorageKey.statedIncome, payload.statedIncome, (income) => income.toString())

        appSessionStorage.setItemIfPresent(localStorageKey.coApplicantStatedIncome, payload.coApplicantStatedIncome, (income) => income.toString())

        appSessionStorage.setItemIfPresent(localStorageKey.applicantMaritalStatus, payload.maritalStatus)
        appSessionStorage.setItemIfPresent(localStorageKey.coApplicantMaritalStatus, payload.coApplicantMaritalStatus)

        appSessionStorage.setItemIfPresent(localStorageKey.applicantSubmittedEmployer, payload.hasSubmittedEmployer, (val) => val.toString())
        appSessionStorage.setItemIfPresent(localStorageKey.coApplicantSubmittedEmployer, payload.hasCoApplicantSubmittedEmployer, (val) => val.toString())
    }
}

interface AbandonApplicationParams {
    wantsToAbandonCurrentApplication: boolean
    returnToken2ForPriorApplication: string
    isCoApplicant: boolean
}
const abandonApplication = async ({ wantsToAbandonCurrentApplication, returnToken2ForPriorApplication, isCoApplicant }: AbandonApplicationParams) => {
    return await httpClient.post('/origination/abandonApplication', { wantsToAbandonCurrentApplication, returnToken2ForPriorApplication, isCoApplicant })
}

const getNextCryptoApplicationAction = async () => {
    return await httpClient.get('/crypto/nextApplicationAction')
}

const getCryptoPrice = async () => {
    return await httpClient.get('/crypto/prices')
}

const plaidReportFetchState = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/plaidReportFetchState')
    }
    return await httpClient.get('/plaidReportFetchState')
}

const postPhoneNumber = async (phoneNumber: string) => {
    return await httpClient.post('/updateApplicantPhoneNumber', {
        phoneNumber,
    })
}

const postAddress = async (addressApt: string, addressStreet: string, addressCity: string, addressState: string, addressPostalCode: string) => {
    return await httpClient.post('/updateApplicantAddress', {
        addressApt: addressApt || '',
        addressStreet,
        addressCity,
        addressState,
        addressPostalCode,
    })
}

const postFullName = async (lastName: string, firstName: string, middleName: string) => {
    const body = {
        firstName,
        lastName,
        middleName: middleName ?? undefined,
    }
    return await httpClient.post('/updateApplicantName', body)
}

const postDateOfBirth = async (dateOfBirth: string) => {
    logger.info(`dateOfBirth: ${dateOfBirth}`)
    return await httpClient.post('/updateApplicantDob', {
        dateOfBirth: dateOfBirth,
    })
}

const postIncome = async (income: string, incomeType: string) => {
    return await httpClient.post('/updateApplicantStatedIncome', {
        statedIncome: income,
        statedIncomeType: incomeType,
    })
}

const getLegalDocument = async (docType: LegalDocumentTypes) => {
    return await httpClient.get('/legal', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

const getLegalDocumentForSession = async (docType: LegalDocumentTypes) => {
    return await httpClient.get('/legal/documentForSession', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

enum LegalDocumentTypes {
    hudGovDoc = 'HomeOwnershipCounselingServices',
    creditScoreDisclosure = 'CreditScoreDisclosure',
    shortFormDeedOfTrust = 'ShortFormDeedOfTrust',
    longFormDeedOfTrust = 'LongFormDeedOfTrust',
    certificationOfTrust = 'CertificationOfTrust',
    helocDeedOfTrust = 'HelocDeedOfTrust',
    lastTransferDocument = 'LastTransferDocument',
    accountAgreement = 'AccountAgreement',
    accountAgreementCrypto = 'AccountAgreementCrypto',
    noticeOfRightToCancel = 'NoticeOfRightToCancel',
    adverseActionNotice = 'AdverseActionNotice',
    fictitiousDeedOfTrust = 'FictitiousDeedOfTrust',
    earlyHELOCDisclosure = 'EarlyHELOCDisclosure',
    pricingAndTerms = 'PricingAndTerms',
    propertyValuation = 'PropertyValuation',
    appraisalWaiver = 'AppraisalWaiver',
}

const postLegalDocuments = async (docTypes: string[]) => {
    return await httpClient.post('/legal', {
        docTypes: docTypes,
    })
}

const startPlaidReportFetch = async (plaidPublicToken: string, plaidDefaultAccountId: string, institutionInfo: string, isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/startPlaidReportFetch', {
            public_token: plaidPublicToken,
            default_account_id: plaidDefaultAccountId,
            institutionInfo,
        })
    }
    return await httpClient.post('/startPlaidReportFetch', {
        public_token: plaidPublicToken,
        default_account_id: plaidDefaultAccountId,
        institutionInfo,
    })
}

const updateApplicantNameFromNonEntityOwnerName = async (selectedOwnerName: { first: string; last: string }) => {
    return await httpClient.post('/updateApplicantNameFromNonEntityOwnerName', { selectedOwnerName })
}

/////////// LOAN OFFER EXPERIMENT APIs ///////////
export interface IUpdateApplicantInfoPayload {
    firstName?: string
    middleName?: string
    lastName?: string
    phoneNumber?: string
    addressFromMultiFieldForm?: boolean
    dateOfBirth?: string
    ssn?: string
    email?: string
}
const postUpdateApplicantFields = async (postBody: IUpdateApplicantInfoPayload) => {
    return await httpClient.post('/updateApplicantFields', postBody)
}

export interface IUpdateApplicantAddressPayload {
    addressApt?: string
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostalCode: string
    residenceType: ResidenceType
    isPropertyAddress: boolean
}
const postUpdateAddress = async (postBody: IUpdateApplicantAddressPayload) => {
    return await httpClient.post('/updateApplicantAddress', postBody)
}

const getDidUnderwritingRelatedInfoChange = async (postBody: Object) => {
    return await httpClient.post('/getDidUnderwritingRelatedInfoChange', postBody)
}

// Keep in sync with enum in aven_backend/src/entity/applicant.ts
enum MaritalStatus {
    MARRIED = 'MARRIED',
    UNMARRIED = 'UNMARRIED',
    SEPARATED = 'SEPARATED',
}

// Keep in sync with enum in aven_backend/src/entity/home.ts
enum ResidenceType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

// Mirrored in aven_backend and aven_python
enum StatedUsageType {
    HOME_IMPROVEMENT = 'Home Improvement',
    BALANCE_TRANSFER = 'Balance Transfer',
    OTHER = 'Other',
    UNSPECIFIED = 'UNSPECIFIED',
}

const getLegalDocumentDownload = async (docType: string) => {
    return await httpClient.get('/legal/document', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

const uploadDocument = async (fileUrl: string, documentTypePath: string, documentIndex: string, isCoApplicant?: boolean) => {
    const formData = new FormData()
    formData.append('file', fileUrl)
    if (isCoApplicant) {
        return await coApplicantHttpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            timeout: 100000,
        })
    }
    return await httpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 100000,
    })
}

const getIsWaitingOnManualIncomeVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/getIsWaitingOnManualIncomeVerification', {})
    }
    return await httpClient.get('/getIsWaitingOnManualIncomeVerification', {})
}

const getOfferContingencyStatus = async () => {
    return await httpClient.get('/getOfferContingencyStatus', {})
}

const beginPayStubVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/bank/beginPayStubVerification', {})
    }
    return await httpClient.get('/bank/beginPayStubVerification', {})
}

const beginTaxReturnVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/bank/beginTaxReturnVerification', {})
    }
    return await httpClient.get('/bank/beginTaxReturnVerification', {})
}

const getPrefilledInformationForMailerLead = async (inviteCode: string) => {
    return await httpClient.post('/getPrefilledInformationForMailerLead', { inviteCode })
}

const sendUserFeedbackEmail = async (feedbackText: string, applicantId: number) => {
    return httpClient.post('/userFeedback/email', {
        feedbackText,
        applicantId,
    })
}

const createTrustPilotUniqueLink = async (returnToken2: string) => {
    return httpClient.post('/createTrustPilotUniqueLink', {
        returnToken2,
    })
}

const getTrustInfo = async () => {
    return await httpClient.get('/getTrustInfo')
}

const postTrustInfoForm = async (postBody: object) => {
    return await httpClient.post('/postTrustInfoForm', postBody)
}

const getSigningUrl = async () => {
    return await httpClient.post('/crypto/getSigningUrl')
}

const runFinishedSigningTasks = async () => {
    return await httpClient.post('/crypto/runFinishedSigningTasks', {}, { timeout: 100000 })
}

const getDocuSignLegalDownload = async (docType: string) => {
    return await httpClient.get('/crypto/document', {
        responseType: 'blob',
        params: {
            docType: docType,
        },
    })
}

export {
    abandonApplication,
    getTrustInfo,
    postTrustInfoForm,
    getPrefilledInformationForMailerLead,
    getIsWaitingOnManualIncomeVerification,
    getOfferContingencyStatus,
    beginPayStubVerification,
    beginTaxReturnVerification,
    getApplicantReturning,
    getNextCryptoApplicationAction,
    getLegalDocument,
    getLegalDocumentForSession,
    LegalDocumentTypes,
    postLegalDocuments,
    saveApplicantSessionResponse,
    plaidReportFetchState,
    startPlaidReportFetch,
    postAddress,
    postFullName,
    postDateOfBirth,
    postPhoneNumber,
    postIncome,
    postUpdateApplicantFields,
    postUpdateAddress,
    getDidUnderwritingRelatedInfoChange,
    getLegalDocumentDownload,
    uploadDocument,
    sendUserFeedbackEmail,
    updateApplicantNameFromNonEntityOwnerName,
    MaritalStatus,
    StatedUsageType,
    ResidenceType,
    createTrustPilotUniqueLink,
    getSigningUrl,
    runFinishedSigningTasks,
    getDocuSignLegalDownload,
    getCryptoPrice,
}
